.resultbox{
    text-align: center;
    margin:10px;
}
.resultbox img{
    width:100%;
    border:solid 1px black;
}
.resultbox h5{
    text-align: center;
    color: #800000;
}
.resultbox h6{
    text-align: center;
} 
@media screen  and (max-width:800px){
    .result h4::before,.result h4::after{
        width:0 !important;
    }
    .result h4{
        font-size:90%;
    }
}