.saarea{
    text-align: center;
}

.saarea img{
    padding:10px;
    background: white;
    border:solid 1px rgb(61, 61, 61);
    border-radius: 5px;
    width:100%;
}
.saareadetails p{
    text-align: center;
    margin:0;
}
.saareadetails p:first-child{
    font-weight: bold;
}
.circlearea{
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    padding:50px 0;
}
.circle{
    background:coral;
    padding:100px 0;
    width:25%;
    height:100px;
    margin:10px;
    text-align: center;
    font-weight: bold;
}
.house h3{
    text-align: center;
    color: #601604;
    margin: 25px 0;
    text-transform: capitalize;
}
.sArea{
    margin:10px 0;
    text-align: center;
    background: #FFF;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}
.sArea img{
    width:100%;
}
.sArea h6{
    font-weight: bold;
    color:   #511303;
    margin:5px 0;
}
.sArea p{
    text-align: center;
    margin: 0;
}
.saline{
    margin: 50px 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, transparent,black,transparent);
}