@font-face {
  font-family: "Lithos Pro Regular";
  src: url("https://db.onlinewebfonts.com/t/7a4d96ad23e1f72b4f917685b9d0a905.eot");
  src: url("https://db.onlinewebfonts.com/t/7a4d96ad23e1f72b4f917685b9d0a905.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/7a4d96ad23e1f72b4f917685b9d0a905.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/7a4d96ad23e1f72b4f917685b9d0a905.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/7a4d96ad23e1f72b4f917685b9d0a905.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/7a4d96ad23e1f72b4f917685b9d0a905.svg#Lithos Pro Regular")
      format("svg");
}
body {
  margin: 0;
  padding: 0;
}
p {
  text-align: justify;
}
.navbar-toggler {
  float: right !important;
}
.kmps {
  width: 120%;
}
@media screen and (max-width: 1000px) {
  .kmps {
    width: 120%;
  }
}

.nav-link {
  font-weight: bold;
  color: maroon !important;
  text-align: center;
  position: relative;
}
.nav-link:before {
  content: "";
  position: absolute;
  height: 2px;
  top: 80%;
  width: 80%;
  background: white;
  transform-origin: bottom;
  transform: scaleX(0);
  transition: transform 0.2s;
  border-radius: 2px;
}
.nav-link:hover:before {
  transform: scaleX(1);
}
.nav-link .active {
  text-shadow: 5px 5px 5px red;
}
.top {
  border-top: solid 5px #a12c2f;
}
.mpstoparea {
  background: linear-gradient(
    to right,
    rgb(214, 140, 3) 0%,
    #faa630 10%,
    #faa630 90%,
    rgb(214, 140, 3) 100%
  );
}
.header-info {
  overflow: hidden;
  border-bottom: 1px solid #743800;
  text-align: right;
}
.header-info ul {
  margin: 0;
  padding: 0;
}
.header-info ul li {
  display: inline-block;
  color: #777777;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 0.1px;
  font-weight: bold;
  padding-left: 15px;
  margin-right: 10px;
  border-left: 1px solid #2c2c2c;
}
.header-info ul li a:link,
.header-info ul li a:visited {
  color: #000;
  text-decoration: none;
  transition: all 2s;
}
.header-info ul li a:hover {
  color: #2b2929;
}
.header-info ul li i {
  margin: 0 5px;
}
.header-info ul li:first-child,
.header-info ul li:last-child {
  border-left: none;
}
.header-info ul li:last-child a {
  background-color: #a12c2f;
  padding: 15px 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 2px;
  display: inline-block;
  text-decoration: none;
  background-size: 300% 100%;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.btn-hover:hover {
  background-position: 100% 0;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.header-info ul li:last-child a:hover {
  background-image: linear-gradient(
    to right,
    #eb3941,
    #f15e64,
    #e14e53,
    #e2373f
  );
  box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
}
.inner-body h4 {
  text-align: center !important;
  overflow: hidden;
  font-weight: bolder;
  text-shadow: #474747 1px 1px 2px;
  color: maroon;
  margin: 20px 0;
  font-family: "Lithos Pro Regular", Arial, Helvetica, sans-serif;
}
.inner-body strong {
  color: #882427;
}
.inner-body h4:before,
.inner-body h4:after {
  width: 50%;
  height: 1px;
  position: relative;
  background: maroon;
  vertical-align: middle;
  display: inline-block;
  content: "";
}
.inner-body h4:after {
  left: 10px;
  margin-right: -50%;
}
.inner-body h4:before {
  right: 10px;
  margin-left: -50%;
}
.inner-body table {
  border-collapse: collapse;
  background: #fff;
}
.inner-body table,
.inner-body tr,
.inner-body th,
.inner-body td {
  border: solid 1px #000 !important;
}
.inner-body th {
  background: maroon;
  color: #fff;
}
.inner-body td {
  text-align: left;
}
.inner-body a:link,
.inner-body a:visited {
  color: maroon;
}
.inner-body .btn-primary {
  background: maroon !important;
  border-color: rgb(58, 1, 1) !important;
  margin: 5px 10px;
  color: #fff !important;
  font-weight: bold;
  padding: 10px 25px;
  transition: all 1s;
}
.inner-body .btn-primary:hover {
  background: rgb(184, 2, 2) !important;
}

.inner {
  background: #fccf94;
}
.inner-head-outer {
  background: rgba(0, 0, 0);
  width: 50%;
  margin: 20px auto;
  padding: 0 10px;
}
@media screen and (max-width: 1000px) {
  .inner-head-outer {
    width: 85%;
  }
}
.inner-head {
  border-left: solid 1px white;
  border-right: solid 1px white;
  background: rgba(0, 0, 0);
  width: 100%;
  padding: 10px 0 0 0;
  text-align: center;
}
.mpsdata {
  height: 25px;
}
.breadcrumb {
  background: none !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.inner h2 {
  text-align: center;
  color: #f09116;
  font-weight: bold;
  text-shadow: 2px 1px 0 #222222;
  margin: 0;
  font-family: "Lithos Pro Regular", Arial, Helvetica, sans-serif;
  margin: 0;
}
.inner .breadcrumb {
  text-align: center;
  margin: 0;
}
.breadcrumb-item a:link,
.breadcrumb-item a:visited {
  color: #d14146;
  font-weight: bold;
}
.breadcrumb-item.active {
  color: #fff !important;
}
.relative {
  position: relative;
}
.leftline {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 90%;
  width: 50px;
  background-color: orange;
}
.bottomline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 95%;
  height: 50px;
  background-color: orange;
}
.mib {
  padding: 75px 70px;
  background-color: #fccf94;
}
@media screen and (max-width: 1000px) {
  .leftline {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 90%;
    width: 20px;
    background-color: orange;
  }
  .bottomline {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 95%;
    height: 20px;
    background-color: orange;
  }
  .mib {
    padding: 75px 30px;
    background-color: #fccf94;
  }
}
.bg-orange {
  padding: 75px 0;
  background-color: orange;
}
.bg-white {
  padding: 75px 0;
  background: #fff url("../images/dust.png");
}
.footer {
  /*background: #292929 url('../images/slide1.jpg') no-repeat;*/
  background: #151414;
  background-size: cover;
  padding: 70px 0;
}
.ff1 {
  margin-left: 20px;
}
.footer h4 {
  color: orange;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-shadow: 0.075em 0.08em 0.1em rgba(0, 0, 0, 1);
  text-align: center;
  font-weight: bold;
  font-family: "Lithos Pro Regular", Arial, Helvetica, sans-serif;
}
@media screen and (max-width: 800px) {
  .footer h4 {
    margin-top: 50px;
  }
}
.footer h4:before,
.footer h4:after {
  width: 20%;
  height: 1px;
  position: relative;
  background: white;
  vertical-align: middle;
  display: inline-block;
  content: "";
}
.footer h4:after {
  left: 10px;
  margin-right: -50%;
}
.footer h4:before {
  right: 10px;
  margin-left: -50%;
}
.footer p {
  color: #fff;
}
.footer p strong {
  color: #ff9d3c;
}
.footer a:link,
.footer a:visited {
  color: #fff !important;
  text-decoration: none;
  transition: all 1s;
}
.footer a:hover {
  color: #f69d9e !important;
}
.footer p {
  text-align: left;
}
.fmenu ul {
  list-style: none;
  margin-left: 100px;
  padding: 0;
}
.fmenu ul li {
  transition: all 1s ease;
}
.fmenu ul li:hover {
  transform: translateX(10px);
}
.fmenu ul li a:link,
.footer ul li a:visited {
  color: #ffc48a !important;
  text-decoration: none;
}

.footer-bottom {
  background: #000;
  padding: 10px 0;
  margin-top: 10px;
}
.visitor p {
  text-align: center;
}
.social {
  text-align: center;
  margin-top: 50px;
}

.social .fa {
  color: #fff;
  padding: 10px;
  width: 40px;
  height: 40px;
  border: solid 1px #fff;
  margin: 0 10px;
  transition: all 2s;
}
.social .fa-facebook:hover {
  background: #3a5a97;
}
.social .fa-twitter:hover {
  background: #2ac6f6;
}
.social .fa-youtube:hover {
  background: #e32c28;
}
.social .fa-linkedin:hover {
  background: #0283c7;
}
.kit {
  text-align: center;
  padding: 20px 0;
  background: #202020;
  color: silver;
}
.kit a:link,
.kit a:visited {
  color: orange;
}
.kitc {
  text-align: center;
  padding: 0;
  background: #202020;
  color: silver;
}
.kitc a:link,
.kitc a:visited {
  color: orange;
  font-size: 50%;
}
.noticedetails {
  padding: 50px 0;
}
.mpspay {
  display: none;
}
@media screen and (max-width: 1000px) {
  .mpspay {
    display: block;
    text-align: center;
  }
  .mpspay a:link,
  .mpspay a:visited {
    color:orange;
    border:solid 1px maroon;
    padding:8px 20px;
    font-weight:bold;
    background: #1f1f1f;
  }
}
