.special td:first-child {
  text-align: center;
}
.special h5 {
  background: #f0922b;
  padding: 10px;
  color: #000000;
  font: bold;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  .special table,
  .special thead,
  .special tbody,
  .special th,
  .special td,
  .special tr {
    display: block !important;
  }
  .special td:first-child {
    text-align: left;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  .special thead tr {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }
  .special tr:first-child {
    display: none !important;
  }
  .special tr {
    margin: 0 0 1rem 0 !important;
  }

  .special tr:nth-child(odd) {
    background: #fff !important;
  }

  .special td {
    /* Behave  like a "row" */
    border: none !important;
    border-bottom: 1px solid #eee !important;
    position: relative !important;
    padding-left: 45% !important;
    font-size: 90%;
  }

  .special td:before {
    /* Now like a table header */
    position: absolute !important;
    /* Top/left values mimic padding */
    top: 0 !important;
    left: 6px !important;
    width: 40% !important;
    white-space: nowrap !important;
  }

  /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
  .special td:nth-last-of-type(3):before {
    content: "S.N.";
    font-weight: bold;
    padding-top: 10px;
  }
  .special td:nth-last-of-type(2):before {
    content: "INFORMATION";
    font-weight: bold;
    padding-top: 10px;
  }

  .special td:nth-last-of-type(1):before {
    content: "DETAILS";
    font-weight: bold;
    padding-top: 10px;
  }
}
